<template>
  <div>
    <ejs-dialog
      ref="dialog"
      header="비밀번호 찾기"
      allowDragging="true"
      showCloseIcon="true"
      width="320"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section">
                  <div class="section-body" style="border: none">
                    <div style="margin-top: -3px">
                      비밀번호를 잊어버리셨나요?<br />
                      정보 입력시 비밀번호를 초기화하여 보내드립니다.
                    </div>
                    <div style="margin-top: 14px">
                      <input
                        type="email"
                        placeholder="이메일"
                        v-model="email"
                        style="
                          box-sizing: border-box;
                          box-shadow: none;
                          border: 1px solid #e0e0e0;
                          border-radius: 3px;
                          width: 100%;
                          height: 36px;
                          padding: 0 9px;
                        "
                      />
                    </div>
                    <div style="margin-top: 5px">
                      <input
                        type="text"
                        placeholder="이름"
                        v-model="name"
                        style="
                          box-sizing: border-box;
                          box-shadow: none;
                          border: 1px solid #e0e0e0;
                          border-radius: 3px;
                          width: 100%;
                          height: 36px;
                          padding: 0 9px;
                        "
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button button-div="SAVE" @click.native="onSubmitClick">
                전송
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onCloseClick">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "FindPasswordPopup",
  mixins: [messagePopupDialogMixin],
  components: {
    ErpButton,
  },
  data() {
    return {
      email: "",
      name: "",
    };
  },
  computed: {
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        enableResize: false,
      };
    },
  },
  methods: {
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    async onSubmitClick() {
      if (!this.email) {
        this.errorToast("이메일을 입력해주세요.");
        return;
      }

      if (!this.name) {
        this.errorToast("이름을 입력해주세요.");
        return;
      }

      await this.findPassword();

      this.infoToast("임시 비밀번호가 전송되었습니다.");
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },

    async findPassword() {
      await GolfErpAPI.findPassword({
        email: this.email,
        name: this.name,
      });
    },
  },
};
</script>

<style scoped></style>
