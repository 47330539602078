<template>
  <div>
    <ejs-dialog
      ref="dialog"
      header="비밀번호 재설정"
      allowDragging="true"
      showCloseIcon="true"
      width="320"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section">
                  <div class="section-body" style="border: none">
                    <div style="margin-top: -3px">
                      변경할 비밀번호를 입력해주십시오.
                    </div>
                    <div style="margin-top: 14px">
                      <input
                        type="password"
                        placeholder="비밀번호"
                        v-model="newPassword"
                        style="
                          box-sizing: border-box;
                          box-shadow: none;
                          border: 1px solid #e0e0e0;
                          border-radius: 3px;
                          width: 100%;
                          height: 36px;
                          padding: 0 9px;
                        "
                      />
                    </div>
                    <div style="margin-top: 5px">
                      <input
                        type="password"
                        placeholder="비밀번호확인"
                        v-model="repeatPassword"
                        style="
                          box-sizing: border-box;
                          box-shadow: none;
                          border: 1px solid #e0e0e0;
                          border-radius: 3px;
                          width: 100%;
                          height: 36px;
                          padding: 0 9px;
                        "
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="keyColor">
              <erp-button
                button-div="SAVE"
                @click.native="onSubmitClick"
              >
                변경
              </erp-button>
            </li>
            <li v-if="!isInitByNull">
              <erp-button
                button-div="SAVE"
                @click.native="onCancelClick"
              >
                30일 동안 변경하지 않음
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { mapGetters } from "vuex";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "InitPasswordPopup",
  mixins: [messagePopupDialogMixin],
  components: {
    ErpButton,
  },
  data() {
    return {
      password: "",
      newPassword: "",
      repeatPassword: "",
      isInitByNull: false,
      isUpdatePassword: false,
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        enableResize: false,
      };
    },
  },
  methods: {
    show({ password, isInitByNull }) {
      this.password = password;
      this.isInitByNull = isInitByNull || false;
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close", this.isUpdatePassword);
    },

    async onSubmitClick() {
      if (!this.newPassword) {
        this.errorToast("새로운 비밀번호를 입력해주세요.");
        return;
      }
      if (!this.repeatPassword) {
        this.errorToast("비밀번호 확인을 입력해주세요.");
        return;
      }
      if (this.newPassword !== this.repeatPassword) {
        this.errorToast("비밀번호 확인이 맞지 않습니다.");
        return;
      }

      await this.updatePassword();

      this.isUpdatePassword = true;
      this.infoToast("변경되었습니다.");
      this.hide();
    },
    async onCancelClick() {
      await GolfErpAPI.updateKeepDoNotChangePassword(this.userId);

      this.hide();
    },
    onCloseClick() {
      this.hide();
    },

    async updatePassword() {
      await GolfErpAPI.updatePassword(this.password, this.newPassword);
    },
  },
};
</script>

<style scoped></style>
