<template>
  <div style="width: 100%; height: 100%; background-color: #f9f9f9">
    <div style="display: table; width: 100%; height: 100%">
      <div style="display: table-cell; vertical-align: middle">
        <div style="width: 280px; padding: 40px 20px; margin: 0 auto">
          <div class="login-ci">그린잇</div>
          <div
            style="
              font-size: 24px;
              color: #222;
              font-family: 'NanumSquare-Regular', Arial, Verdana, sans-serif;
              text-align: center;
              padding-top: 18px;
            "
          >
            로그인
          </div>
          <div style="text-align: center">
            Golf-ERP 시스템에 오신 것을 환영합니다.
          </div>
          <div class="content-wrapper">
            <div class="content-lookup" style="width: 100%; padding: 6px 0px">
              <div class="lookup-lookup" style="width: 100%; margin: 0">
                <ul class="lookup-condition" style="width: 100%">
                  <li class="field cartDivision" style="width: 100%; margin: 0">
                    <div class="title" style="width:100%; text-align: center">PC 사용코드</div>
                    <ejs-dropdownlist
                        ref="dropDownList"
                        cssClass="lookup-condition-dropdown"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        :dataSource="commonCodePcUseCode"
                        v-model="selectUsePcCode"
                    >
                    </ejs-dropdownlist>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="inputId" style="padding-top: 18px">
            <input
              ref="input-email"
              type="email"
              id="email"
              placeholder="Email"
              spellcheck="false"
              v-model="email"
              @keypress.enter="onSubmit"
              autocomplete="off"
              :disabled="autoCompleteDisabled"
            />
          </div>
          <div class="inputPassword" style="padding-top: 6px">
            <input
              ref="input-password"
              type="password"
              id="password"
              placeholder="Password"
              spellcheck="false"
              v-model="password"
              @keydown="onKeyDown"
              @keypress.enter="onSubmit"
              @mouseleave="onMouseLeave"
              autocomplete="off"
              :disabled="autoCompleteDisabled"
            />
            <div class="caps-lock-alert" v-if="isCapsLockEnabled">
              <div class="box"><b>Caps Lock</b> 이 켜져 있습니다.</div>
            </div>
          </div>
          <div style="padding-top: 12px">
            <div style="float: right">
              <div class="component-check">
                <label>
                  <input type="checkbox" v-model="isSaveID" />
                  <div class="label">
                    <div class="item">아이디 저장</div>
                  </div>
                </label>
              </div>
            </div>
            <div
              style="
                display: block;
                clear: both;
                height: 0;
                font-size: 0;
                line-height: 0;
                content: '';
              "
            ></div>
          </div>
          <div style="padding-top: 30px">
            <erp-button
              button-div="SAVE"
              style="
                box-sizing: border-box;
                box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.06);
                border: 1px solid #2e7458;
                border-radius: 3px;
                background-color: #2e7458;
                width: 100%;
                height: 46px;
                padding: 0 18px 0 18px;
                cursor: pointer;
              "
              @click="onSubmit"
            >
              <div
                style="
                  font-size: 16px;
                  color: #fff;
                  font-family: 'NanumSquare-Bold', Arial, Verdana, sans-serif;
                "
              >
                로그인
              </div>
            </erp-button>
          </div>
          <div style="padding-top: 6px">
            <erp-button
              button-div="SAVE"
              style="
                box-sizing: border-box;
                box-shadow: none;
                border: 1px solid #e0e0e0;
                border-radius: 3px;
                background-color: #fff;
                width: 100%;
                height: 40px;
                padding: 0 18px 0 18px;
                cursor: pointer;
              "
              @click="onFindPasswordClick"
            >
              <div
                style="
                  display: inline-block;
                  position: relative;
                  padding-left: 21px;
                  font-size: 12px;
                  color: #666;
                  font-family: '돋움', Dotum, Arial, Verdana, sans-serif;
                "
              >
                <div
                  style="
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 12px;
                    height: 12px;
                    margin-top: -6px;
                    background: transparent
                      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAAEhcmxxAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjhERDEyQjMzNzNFRTExRUI5QkYwOEFCMTE3Q0FCMTFGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjhERDEyQjM0NzNFRTExRUI5QkYwOEFCMTE3Q0FCMTFGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OEREMTJCMzE3M0VFMTFFQjlCRjA4QUIxMTdDQUIxMUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OEREMTJCMzI3M0VFMTFFQjlCRjA4QUIxMTdDQUIxMUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz49VDURAAABF0lEQVR42mLUK4lgAAEmBgg4AhBADECRPSDeeoAAYgSy9gEZTkCcAxBAIE4kkGEMxCUsQGI5FIN1bgPiwyAOQADBlC1nQICdQOwOUmXAgAq4YdrL0SRsgPgYQAAxQp22HYg5gTgYiN+CBEC2twCxJ1S1CkwCZFQNkjF3gDgIJuGKJMEMdTLYqNtAPBuInwDxRyDeA8RXQBIPgDgVSZcMEGsDBBjMVbOA+CUQdwDxV6gCUEA1A3E81G4GmBVbgRik6zOaP/dB8UwgngTEV2GO5QDiLwy4wVMgFoNxQBrCQCEGxGpoCkG2zwHiv0C8H1kQFGCWUL4mEKsD8XsgPgnEKUAsDcRHgTgN5CwWNFOvQzG6k6yhobQBAPCKOGYP8L84AAAAAElFTkSuQmCC')
                      no-repeat center center;
                  "
                ></div>
                비밀번호 찾기
              </div>
            </erp-button>
          </div>
          <div
            style="
              text-align: center;
              padding-top: 18px;
              font-size: 11px;
              font-family: Verdana, Arial, sans-serif;
            "
          >
            Copyright <strong>GREENIT</strong>, Inc.<br />
            All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
    <init-password-popup
      ref="init-password-popup"
      v-if="visibleInitPasswordPopup"
      @close="onVisibleInitPasswordPopupClose"
    />
    <find-password-popup
      ref="find-password-popup"
      v-if="visibleFindPasswordPopup"
      @close="onFindPasswordPopupClose"
    />
  </div>
</template>

<style scoped>
body .lookup-condition >>> .e-input-group {box-sizing: border-box; padding: 2px 6px 1px 6px;margin: 0;border: 1px solid #e0e0e0 !important;border-radius: 3px;background-color: #fff;}
.login-ci {text-indent: -1000px;overflow: hidden;width: 100%;height: 29px;background: transparent url("../../assets/images/common/login-ci.png") no-repeat center center;}
.inputId input {box-sizing: border-box;box-shadow: none;border: 1px solid #e0e0e0;border-radius: 3px;background: #fff url("../../assets/images/common/login-id.png") no-repeat 18px center;width: 100%;height: 46px;padding: 0 18px 0 44px;}
.inputPassword {position: relative;}
.inputPassword input {box-sizing: border-box;box-shadow: none;border: 1px solid #e0e0e0;border-radius: 3px;background: #fff url("../../assets/images/common/login-password.png") no-repeat 18px center;width: 100%;height: 46px;padding: 0 18px 0 44px;}
.caps-lock-alert {position: absolute;bottom: -24px;left: 0;width: 159px;height: 30px;color: red;font-size: 13px;z-index: 100;}
.caps-lock-alert > .box {font-size: 11px;line-height: 14px;position: static;margin-top: 8px;padding: 9px 9px 7px;letter-spacing: -1px;color: #777;border: solid 1px #d8d1aa;background: #fffadc;}
.caps-lock-alert > .box > b {color: #2e7458;}
.component-check label {display: block;position: relative;}
.component-check label input {display: block;box-sizing: border-box;box-shadow: none;position: absolute;top: 0;left: 0;width: 100%;height: 100%;min-width: 0;min-height: 0;padding: 0;margin: 0;border: none;border-radius: 0;background-color: transparent;opacity: 0;z-index: 20;cursor: pointer;appearance: none;-webkit-appearance: none;}
.component-check label input + .label {box-sizing: border-box;position: relative;min-height: 20px;line-height: 0;}
.component-check label input + .label .item {display: inline-block;position: relative;padding: 1px 0 1px 28px;color: #666;font-size: 12px;font-family: "돋움", Dotum, Arial, Verdana, sans-serif;line-height: 18px;text-align: left;}
.component-check label input + .label .item::before {display: block;box-sizing: border-box;position: absolute;top: 50%;left: 0;width: 20px;height: 20px;margin-top: -11px;border: 1px solid #e0e0e0;border-radius: 3px;background-color: #fff;background-repeat: no-repeat;background-position: center center;content: "";}
.component-check label input:disabled + .label .item {color: #ccc;}
.component-check label input:disabled + .label .item::before {background-color: #f1f1f1;}
.component-check label input:checked + .label {z-index: 10;}
.component-check label input:checked + .label .item::before {border-color: #2e7458;background-color: #2e7458;background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJCAYAAAAGuM1UAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEMxNzdERjg0NTcyMTFFQjhGMjZGRjBEQTE4RDBCMTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEMxNzdERjk0NTcyMTFFQjhGMjZGRjBEQTE4RDBCMTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo4QzE3N0RGNjQ1NzIxMUVCOEYyNkZGMERBMThEMEIxNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4QzE3N0RGNzQ1NzIxMUVCOEYyNkZGMERBMThEMEIxNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtVxCPEAAABXSURBVHjaYvz//z8DkYAFiP8wEamYH4j3ArEWA8gGApgfiA8BcSLYNaQohmkACVoSoximQRqILwKxKyHFyE6SQdKEUzG6H2CazuFSjM3TIE2h+AICIMAAGNILPOJhZoEAAAAASUVORK5CYII=");}
.component-check label input:disabled:checked + .label {z-index: auto;}
.component-check label input:disabled:checked + .label .item::before {border-color: #e0e0e0;background-color: #f1f1f1;background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJCAYAAAAGuM1UAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUY5RUI0QTI0NTcyMTFFQjgxMzFERjIxOUU3ODUxNUYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUY5RUI0QTM0NTcyMTFFQjgxMzFERjIxOUU3ODUxNUYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBRjlFQjRBMDQ1NzIxMUVCODEzMURGMjE5RTc4NTE1RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBRjlFQjRBMTQ1NzIxMUVCODEzMURGMjE5RTc4NTE1RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pti0fxEAAABmSURBVHjaYjxz5gwDkYAFiP8wEamYH4j3ArEWE5GKNwPxAiC+xkSk4vlQzMAEFbQkRjFMAw8QzwBiV0KKYRqeArE3EPdANeFUDAsqEHgC1bQViP8C8WRsipE1IGsC+Wc1rlAACDAA/XYYAGL0BCYAAAAASUVORK5CYII=");}
</style>

<script>
import GolfERPService from "@/service/GolfERPService";
import { mapActions, mapGetters, mapMutations } from "vuex";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import { v4 as createUUID } from "uuid";
import {
  getLoginEmail,
  removeAccessToken,
} from "@/utils/session/SessionUtil";
import InitPasswordPopup from "@/views/login/popup/InitPasswordPopup";
import FindPasswordPopup from "@/views/login/popup/FindPasswordPopup";
import ErpButton from "@/components/button/ErpButton.vue";
import { getCommonCodeUsePc } from "@/api/common";
import {sortBy as _sortBy} from "lodash";
import {commonCodesGetCommonCodeAttrbByCodeAndIdx} from "@/utils/commonCodes";

export default {
  name: "LoginView",
  components: { FindPasswordPopup, InitPasswordPopup, ErpButton },
  mixins: [messagePopupDialogMixin],
  data() {
    return {
      email: "",
      password: "",
      commonCodePcUseCode: [],
      selectUsePcCode: localStorage.getItem('pcUseCode') || null,
      uniqueId: createUUID(),
      isCapsLockEnabled: false,
      isSaveID: false,
      autoCompleteDisabled: true,
      isInitByNull: false,

      visibleInitPasswordPopup: false,
      visibleFindPasswordPopup: false,
    };
  },
  computed: {
    ...mapGetters(["userId"]),
  },
  created() {
    this.email = getLoginEmail();
    this.isSaveID = !!getLoginEmail();
  },
  async mounted() {
    this.SET_LOGIN_VIEW_UNIQUE_ID(this.uniqueId);
    const {data: commonCodes} = await getCommonCodeUsePc();
    this.commonCodePcUseCode = _sortBy(commonCodes || [],"sortNo");
    // 자동 완성 기능 미반영을 위한 삽질
    setTimeout(() => {
      this.autoCompleteDisabled = false;
    }, 1000);
  },
  methods: {
    ...mapActions(["loadApplication"]),
    ...mapMutations(["SET_LOGIN_VIEW_UNIQUE_ID"]),
    onSubmit(event) {
      event.preventDefault();

      this.login(this.email, this.password, this.selectUsePcCode);
    },
    onKeyDown(event) {
      this.isCapsLockEnabled = event.getModifierState("CapsLock");
    },
    onMouseLeave() {
      this.isCapsLockEnabled = false;
    },
    async login(email, password, pcCode) {
      if (!email) {
        this.errorToast("아이디를 입력해주세요.");
        this.$refs["input-email"].focus();
        return;
      }

      // 비밀번호가 초기화된 사용자는 패스워드를 미입력하고 로그인해야하므로 주석 처리.
      // if (!password) {
      //   this.errorToast("패스워드를 입력해주세요.");
      //   this.$refs["input-password"].focus();
      //   return;
      // }
      if(!pcCode) {
        this.errorToast("PC사용코드를 입력해주세요.");
        this.$refs["dropDownList"].focus();
        return;
      }
      const status = await GolfERPService.login(email, password, {
        keepLoginEmail: this.isSaveID,
      });

      if (status === "REQUIRED_INIT_PASSWORD_BY_NULL") {
        this.isInitByNull = true;
        this.visibleInitPasswordPopup = true;
        this.$nextTick(() => {
          this.$refs["init-password-popup"].show({
            password,
            isInitByNull: this.isInitByNull,
          });
        });
        return;
      }

      if(localStorage.getItem('pcUseCode') !== pcCode) {
        localStorage.setItem('pcUseCode',pcCode);
      }
      await this.loadApplication();

      // 메모 닫음
      this.$emit('memoButtonClick', null);

      if (status === "REQUIRED_INIT_PASSWORD") {
        this.visibleInitPasswordPopup = true;
        this.$nextTick(() => {
          this.$refs["init-password-popup"].show({
            password,
          });
        });
      } else {
        const url =
          commonCodesGetCommonCodeAttrbByCodeAndIdx("PC_USE_CODE", pcCode, 1, false) === "TABLET"
            ? "/pos-renew"
            : this.$route.query.redirect;
        await this.$router.push({
          path: url || "/"
        });
      }
    },
    onFindPasswordClick() {
      this.visibleFindPasswordPopup = true;
      this.$nextTick(() => {
        this.$refs["find-password-popup"].show();
      });
    },
    async onVisibleInitPasswordPopupClose(isUpdatePassword) {
      this.visibleInitPasswordPopup = false;
      if (!isUpdatePassword && this.isInitByNull) {
        removeAccessToken();
        this.errorToast("비밀번호가 초기화된 상태입니다. 비밀번호를 변경해 주시기 바랍니다");
      } else {
        await this.$router.push({path: this.$route.query.redirect || "/"});
      }
    },
    async onFindPasswordPopupClose() {
      this.visibleFindPasswordPopup = false;
    },

    // TODO delete
    showInitPasswordPopup() {
      this.visibleInitPasswordPopup = true;
      this.$nextTick(() => {
        this.$refs["init-password-popup"].show({});
      });
    },
    showSessionView() {
      this.$router.push({ path: "/session-expired" });
    },
  },
};
</script>
